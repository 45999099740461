.height-chart{
    height: 91% !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
background-color: transparent;
}


@media screen and (max-width:575px){
  .height-chart{
    padding-bottom: 20px !important;
}
}
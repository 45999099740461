.ant-btn:hover.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn,.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn{
background-color: transparent;
border: transparent;
margin-bottom: 2px;
}
.danger-button,.ant-btn-dangerous.ant-btn-primary.danger-button{
    background: #ff7875 !important;
   color: white !important;
    border-color: #ff7875 !important;

}
.height-card{
    height: 55px !important;
}

 .ant-modal-body {
        max-height: 80vh !important;
    overflow-y: auto !important;
}